import { useState, RefObject } from 'react';
import { createStyles, Header, Container, Group, Title, Burger, Drawer } from '@mantine/core';
import TwoFiveLogo from './images/TwoFiveLogo';
import { ActionToggle } from './DarkMode';
import { useDisclosure } from '@mantine/hooks';

interface HeaderSimpleProps {
  featuresRef: RefObject<HTMLDivElement>;
  forAgentsRef: RefObject<HTMLDivElement>;
  pricingRef: RefObject<HTMLDivElement>;
  contactUsRef: RefObject<HTMLDivElement>;
  links2: { link: string; label: string }[];
  scrollToRefFn: (ref: React.RefObject<HTMLDivElement>) => void;
}

interface NavItem {
  label: string;
  ref: RefObject<HTMLDivElement>;
}

const useStyles = createStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    marginBottom: '20px',
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  linksSL: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  centeredLinks: {
    justifyContent: 'center',
  },

  hasLine: {
    paddingLeft: 10,
    position: 'relative',
  },

  burger: {
    [theme.fn.largerThan('md')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: '12px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 700,
    cursor: 'pointer',


    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      textDecoration: 'underline',
    },
  },

  borderedLink: {
    border: `2px solid ${theme.colors[theme.primaryColor][6]}`,
  },
}));

export function HeaderSimple({
  featuresRef,
  forAgentsRef,
  pricingRef,
  contactUsRef,
  links2,
  scrollToRefFn,
}: HeaderSimpleProps) {
  const { classes } = useStyles();

  const [opened, { toggle }] = useDisclosure(false);

  const navItems: NavItem[] = [
    { label: 'Features', ref: featuresRef },
    { label: 'For Agents', ref: forAgentsRef },
    { label: 'Pricing', ref: pricingRef },
    { label: 'Contact Us', ref: contactUsRef },
  ];

  const renderNavItem = (item: NavItem, key: number) => (
    <a
      key={key}
      className={classes.link}
      onClick={(event) => {
        event.preventDefault();
        scrollToRefFn(item.ref);
      }}
    >
      {item.label}
    </a>
  );

  const items2 = links2.map((link) => (
    <a
      key={link.label}
      href={link.link}
      className={`${classes.link} ${
        link.label === 'Login' || link.label === 'Signup'
          ? classes.borderedLink
          : ''
      }`}
      onClick={(event) => {
        if (link.link.startsWith('http')) {
          // Do not prevent default behavior for external links
          return;
        }
        event.preventDefault();
      }}
    >
      {link.label}
    </a>
  ));

  const burgerMenuContent = (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignContent:'right', padding:'20px'}}>
      {navItems.map(renderNavItem)}
      {items2}
      <ActionToggle />
    </div>
  );

  return (
    <Header height={70} mb={0}>
      <Container className={classes.header} fluid>
        <Group>
          <TwoFiveLogo />
          <Title className={classes.hasLine} order={3}>
          TwoFive tax
        </Title>
        </Group>
        <Group className={classes.links} position="apart">
          {navItems.map(renderNavItem)}
        </Group>
        <Group className={classes.linksSL}>
        {items2}
        <ActionToggle />
        </Group>
        <Burger opened={opened} onClick={toggle} className={classes.burger} size="md" />
        <Drawer opened={opened} onClose={toggle} position='right' size='xs'>{burgerMenuContent}</Drawer>
      </Container>
    </Header>
  );
}




export default HeaderSimple;
