import {
  createStyles,
  Text,
  Title,
  SimpleGrid,
  TextInput,
  Textarea,
  Button,
  Group,
  ActionIcon,
} from '@mantine/core';
import { IconBrandTwitter, IconBrandLinkedin } from '@tabler/icons';
import { ContactIconsList } from './ContactIcons';
import { useForm } from '@mantine/form';
import axios from 'axios';
const baseApiUrl = 'https://app.25tax.co.uk/api';

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 400,
    boxSizing: 'border-box',
    backgroundImage: `linear-gradient(-60deg, ${theme.colors[theme.primaryColor][4]} 0%, ${
      theme.colors[theme.primaryColor][7]
    } 100%)`,
    borderRadius: theme.radius.md,
    padding: theme.spacing.xl * 2.5,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      padding: theme.spacing.xl * 1.5,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    color: theme.white,
    lineHeight: 1,
  },

  description: {
    color: theme.colors[theme.primaryColor][0],
    maxWidth: 300,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: '100%',
    },
  },

  form: {
    backgroundColor: theme.white,
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.lg,
  },

  social: {
    color: theme.white,

    '&:hover': {
      color: theme.colors[theme.primaryColor][1],
    },
  },

  input: {
    backgroundColor: theme.white,
    borderColor: theme.colors.gray[4],
    color: theme.black,
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
      WebkitTextFillColor: theme.black,
    },
    '&::placeholder': {
      color: theme.colors.gray[5],
    },
  },

  inputLabel: {
    color: theme.black,

  },

  control: {
    backgroundColor: theme.colors[theme.primaryColor][6],
  },
}));

const social = [
  { Icon: IconBrandTwitter, url: "https://twitter.com/TwoFiveTax/" },
  { Icon: IconBrandLinkedin, url: "https://www.linkedin.com/company/25-tax/" },
];

export function ContactUs() {
  const { classes } = useStyles();
  const form = useForm({
    initialValues: {
      name: '',
      email: '',
      number: '',
      message: '',
      subject: '',
    },
    validate: {
      name: (value) => value.trim().length < 2,
      email: (value) => !/^\S+@\S+$/.test(value),
      message: (value) => value.trim().length === 0,
    },
  });

  const sendMessage = async (values: { name: string; email: string; number: string; message: string; subject: string; }) => {
    try {
      await axios.post(`${baseApiUrl}/contact-us`, values);
      alert('Message sent');
    } catch (err) {
      alert('There was an error while sending your message');
    }
    form.reset();
  };

  const icons = social.map(({ Icon, url }, index) => (
    <a href={url} target="_blank" rel="noopener noreferrer" key={index}>
      <ActionIcon size={28} className={classes.social} variant="transparent">
        <Icon size={22} stroke={1.5} />
      </ActionIcon>
    </a>
  ));



  return (
    <div className={classes.wrapper}>
      <SimpleGrid cols={2} spacing={50} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
        <div>
          <Title className={classes.title}>Contact us</Title>
          <Text className={classes.description} mt="sm" mb={30}>
            Leave your email and we will get back to you within 24 hours
          </Text>

          <ContactIconsList variant="white" />

          <Group mt="xl">{icons}</Group>
        </div>
        <div className={classes.form}>
        <form
            onSubmit={(event) => {
              event.preventDefault();
                sendMessage(form.values);
            }}
    >
          <TextInput
            label="Email"
            placeholder="your@email.com"
            required
            classNames={{ input: classes.input, label: classes.inputLabel }}

            {...form.getInputProps("email")}
          />
          <TextInput
            label="Number"
            placeholder="+44 77 234 567 89"
            mt="md"
            classNames={{ input: classes.input, label: classes.inputLabel }}
            {...form.getInputProps("number")}
          />
          <TextInput
            label="Name"
            placeholder="John Doe"
            mt="md"
            classNames={{ input: classes.input, label: classes.inputLabel }}
            {...form.getInputProps("name")}
          />
          <TextInput
            label="Subject"
            placeholder="I'd like to learn more about MTDey Wydey"
            required
            classNames={{ input: classes.input, label: classes.inputLabel }}
            {...form.getInputProps("subject")}
          />
          <Textarea
            required
            label="Your message"
            placeholder="I'd like some assistance setting up an account."
            minRows={4}
            mt="md"
            classNames={{ input: classes.input, label: classes.inputLabel }}
            {...form.getInputProps("message")}
          />

          <Group position="right" mt="md">
            <Button type='submit' className={classes.control}>Send message</Button>
          </Group>
          </form>
        </div>
      </SimpleGrid>
    </div>
  );
}
