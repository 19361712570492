import {
  createStyles,
  Badge,
  Group,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
} from '@mantine/core';
import { IconJetpack, IconPigMoney, IconPhoneCheck, IconPlugConnected, IconDoorEnter
} from '@tabler/icons';

const data = [
  {
    title: 'Comprehensive support',
    description:
      'Get a quick response to your issue or query via our email, phone, and live chat support services.',
    icon: IconPhoneCheck,
  },
  {
    title: 'Fair Pricing',
    description:
      'Affordable and transparent pricing structure without pressuring users into unnecessary up-sells.',
    icon: IconPigMoney,
  },
  {
    title: 'Future-proofed',
    description:
      'We\'re prepared for digital Income Tax & Self-Assessment (ITSA), and Corporation Tax (CT) when the time comes',
    icon: IconJetpack,
  },
  {
    title: 'Accounting System Integration',
    description:
      'TwoFive can read select accounting system files directly for MTD figures.',
    icon: IconPlugConnected,
  },
  {
    title: 'Easy Onboarding',
    description:
      'Simple onboarding process with an interactive practice run for new users.',
    icon: IconDoorEnter,
  },
];

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: 34,
    fontWeight: 900,
    [theme.fn.smallerThan('sm')]: {
      fontSize: 24,
    },
  },

  description: {
    maxWidth: 600,
    margin: 'auto',

    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  card: {
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  cardTitle: {
    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },
}));

export function FeaturesCards() {
  const { classes, theme } = useStyles();
  const features = data.map((feature) => (
    <Card key={feature.title} shadow="md" radius="md" className={classes.card} p="xl">
      <feature.icon size={50} stroke={2} color={theme.fn.primaryColor()} />
      <Text size="lg" weight={500} className={classes.cardTitle} mt="md">
        {feature.title}
      </Text>
      <Text size="sm" color="dimmed" mt="sm">
        {feature.description}
      </Text>
    </Card>
  ));
  return (
    <Container size="lg" py="xl">

      <Title order={2} className={classes.title} align="center" mt="sm">
       The essentials, and some things that should be
      </Title>

      <Text color="dimmed" className={classes.description} align="center" mt="md">
      twofive is focused on offering a user-friendly and efficient tax filing experience while saving you time and money. Our features accommodate all users, and we continually strive to improve our service.
      </Text>


      <SimpleGrid cols={3} spacing="xl" mt={50} breakpoints={[{ maxWidth: 'md', cols: 1 }]} >
        {features}
      </SimpleGrid>
    </Container>
  );
}
