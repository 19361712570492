import { createStyles, Text, SimpleGrid, Container } from '@mantine/core';
import { IconUser, IconUsers, TablerIcon, IconFileInvoice, IconRuler } from '@tabler/icons';
import { Title } from "@mantine/core";


const useStyles = createStyles((theme) => ({
  feature: {
    position: 'relative',
    paddingTop: theme.spacing.xl,
    paddingLeft: theme.spacing.xl,
  },

  overlay: {
    position: 'absolute',
    height: 100,
    width: 160,
    top: 0,
    left: 0,
    backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
    zIndex: 1,
  },

  content: {
    position: 'relative',
    zIndex: 2,
  },

  icon: {
    color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 36,
    fontWeight: 900,
    lineHeight: 1.1,
    marginBottom: theme.spacing.md,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
  },


}));

interface FeatureProps extends React.ComponentPropsWithoutRef<'div'> {
  icon: TablerIcon;
  title: string;
  description: string;
}

function Feature({ icon: Icon, title, description, className, ...others }: FeatureProps) {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.feature, className)} {...others}>
      <div className={classes.overlay} />

      <div className={classes.content}>
        <Icon size={38} className={classes.icon} stroke={1.5} />
        <Title weight={700} size="lg" mb="xs" mt={5}>
          {title}
        </Title>
        <Text color="dimmed" size="sm">
          {description}
        </Text>
      </div>
    </div>
  );
}

const data = [
  {
    icon: IconUser,
    title: 'Business account',
    description:
      '£6.99 per quarter for one VAT registration.',
  },
  {
    icon: IconUsers,
    title: 'Multi-VAT business',
    description:
      '£4.99 per quarter per VAT registration',
  },
  {
    icon: IconFileInvoice,
    title: 'Agent Account',
    description:
      '£3.99 per submission',
  },
  {
    icon: IconRuler,
    title: 'Whitelabel & bespoke services',
    description:
      'Get in touch to discuss whitelabel oppourtunites or unique business cases.',
  },
];

export function FeaturesAsymmetrical() {
  const items = data.map((item) => <Feature {...item} key={item.title} />);
  const { classes, cx } = useStyles();
  return (
    <Container mt={30} mb={30} size="lg">
      <Title order={2} align="center" mt="sm" className={classes.title}>
        Our products and pricing
      </Title>
      <br />
      <Text align="center">
        TwoFive offers a range of affordable pricing options to cater to different user requirements.
      </Text>
      <br />
      <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'sm', cols: 1 }]} spacing={50} ta="center">
        {items}
      </SimpleGrid>
    </Container>
  );
}
