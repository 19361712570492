import { createStyles, Title, SimpleGrid, Text, Button, ThemeIcon, Grid, Col } from '@mantine/core';
import { IconTargetArrow, IconBoxMultiple, IconUserCircle, IconFileAnalytics  } from '@tabler/icons';

const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: `${theme.spacing.xl * 2}px ${theme.spacing.xl}px`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 36,
    fontWeight: 900,
    lineHeight: 1.1,
    marginBottom: theme.spacing.md,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
  },
}));

const features = [
  {
    icon: IconTargetArrow,
    title: 'Simple Submission Management',
    description: 'An intuitive interface with timely alerts helps you file returns accurately and avoid late fees.',
  },
  {
    icon: IconBoxMultiple,
    title: 'One Dashboard for Multiple Clients',
    description: 'Search, filter, and group clients with ease using our centralized dashboard.',
  },
  {
    icon: IconUserCircle,
    title: 'Customisable User Permissions',
    description: 'Assign different permissions to maintain data security and safeguard your organization.',
  },
  {
    icon: IconFileAnalytics,
    title: 'Gather Information Quickly',
    description: 'Generate reports or batch download previous returns for specific periods, groups, or registrations with just a few clicks',
  },
];

export function FeaturesTitle() {
  const { classes } = useStyles();

  const items = features.map((feature) => (
    <div key={feature.title}>
      <ThemeIcon
        size={44}
        radius="md"
        variant="gradient"
        gradient={{ deg: 133, from: 'blue', to: 'cyan' }}
      >
        <feature.icon size={26} stroke={1.5} />
      </ThemeIcon>
      <Text size="lg" mt="sm" weight={500}>
        {feature.title}
      </Text>
      <Text color="dimmed" size="sm">
        {feature.description}
      </Text>
    </div>
  ));

  return (
    <div className={classes.wrapper}>
      <Grid gutter={80}>
        <Col span={12} md={5}>
          <Title className={classes.title} order={2}>
            Filing on behalf of someone else?
          </Title>
          <Text color="dimmed">
          TwoFive offers a suite of features designed specifically for tax agents, ensuring efficient management of multiple clients and streamlined submission processes.
          </Text>

          <a href="https://app.25tax.co.uk/auth/signup">
            <Button
              variant="gradient"
              gradient={{ deg: 133, from: 'blue', to: 'cyan' }}
              size="lg"
              radius="md"
              mt="xl"
            >
              Get started
            </Button>
          </a>

        </Col>
        <Col span={12} md={7}>
          <SimpleGrid cols={2} spacing={30} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
            {items}
          </SimpleGrid>
        </Col>
      </Grid>
    </div>
  );
}
