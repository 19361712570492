import { Image, ThemeIcon } from "@mantine/core";

// import logo from "../images/icon-logo.jpeg";
import logo from "./icon-logo-trans.png";

function TwoFiveLogo() {
  return (
    <ThemeIcon size={45}>
      <Image src={logo}/>
    </ThemeIcon>
  );
}

export default TwoFiveLogo;
