export const headerLinks2 = [
  {
    label: "Login",
    link: "https://app.25tax.co.uk/login",
  },
  {
    label: "Signup",
    link: "https://app.25tax.co.uk/",
  },
];

export const footerLinks = [
  {
    label: "Privacy",
    link: "/",
  },
  {
    label: "HMRC page",
    link: "/",
  },
];
