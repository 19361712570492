import { Stack, Container, Title } from "@mantine/core";
import { HeaderSimple } from "./components/Header";
import { HeroText } from './components/Hero';
import { FeaturesCards } from "./components/FeatureCards";
import { FeaturesTitle } from "./components/AgentFeatureCard";
import { FooterCentered } from "./components/Footer";
import { ContactUs } from "./components/ContactUs";
import { FeaturesAsymmetrical } from "./components/Pricing";
import {
  MantineProvider,
  ColorSchemeProvider,
  ColorScheme,
  Space
} from "@mantine/core";
import { useState, useRef, useEffect } from "react";
import { headerLinks2, footerLinks } from "./Links";
import Cookies from "js-cookie";

export default function App() {
  const featuresRef = useRef(null);
  const forAgentsRef = useRef(null);
  const pricingRef = useRef(null);
  const contactUsRef = useRef(null);

  const [colorScheme, setColorScheme] = useState<ColorScheme>(
    () => (Cookies.get("mantine-color-scheme") || "light") as ColorScheme
  );

  useEffect(() => {
    Cookies.set("mantine-color-scheme", colorScheme, { expires: 999999 });
  }, [colorScheme]);

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{
          colorScheme,
          fontFamily: "Poppins, sans-serif",
          headings: {
            sizes: {
              h5: { fontSize: 18 },
            },
          },
        }}
        withGlobalStyles
        withNormalizeCSS
      >
        <HeaderSimple
          featuresRef={featuresRef}
          forAgentsRef={forAgentsRef}
          pricingRef={pricingRef}
          contactUsRef={contactUsRef}
          links2={headerLinks2}
          scrollToRefFn={(ref: React.RefObject<HTMLDivElement>) => scrollToRef(ref)}
        />
        <Stack spacing={'xl'} align={'center'} justify={'space-around'}>
          <Title size={18}>Warning: the TwoFive product is currently in early access. For more information, please contact george@25tax.co.uk.</Title>
          <Container>
            <HeroText contactUsRef={contactUsRef} scrollToRef={scrollToRef} />
          </Container>
          <Container ref={featuresRef}>
            <FeaturesCards />
          </Container>
          <Container ref={forAgentsRef}>
            <FeaturesTitle />
          </Container>
          <Container ref={pricingRef}>
            <FeaturesAsymmetrical />
          </Container>
          <Container ref={contactUsRef}>
            <ContactUs />
          </Container>
        </Stack>
        <FooterCentered
          links={footerLinks}
        />
      </MantineProvider>
    </ColorSchemeProvider>
  );
}
