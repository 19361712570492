import {
  createStyles,
  Title,
  Text,
  Button,
  Container,
} from '@mantine/core';

interface HeroTextProps {
  contactUsRef: React.RefObject<HTMLDivElement>;
  scrollToRef: (ref: React.RefObject<HTMLDivElement>) => void;
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: 100,
    paddingBottom: 60,
    "@media (max-width: 755px)": {
      paddingTop: 60,
      paddingBottom: 40,
    },
  },

  mainTitle: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: 52,
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    "@media (max-width: 520px)": {
      fontSize: 32,
      textAlign: "left",

    },
  },

  subTitle: {
    textAlign: "center",
    fontWeight: 600,
    fontSize: 32,
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    marginTop: theme.spacing.xl * 2, // Increase the margin-top value

    "@media (max-width: 520px)": {
      fontSize: 28,
      textAlign: "left",
      marginTop: theme.spacing.xl, // Adjust the margin-top value for smaller screens
    },
  },

  inner: {
    position: 'relative',
    zIndex: 1,
  },

  titleWrapper: {
    textAlign: 'center',
    marginBottom: theme.spacing.lg,
  },

  highlight: {
    color:
      theme.colors[theme.primaryColor][
        theme.colorScheme === 'dark' ? 4 : 6
      ],
  },

  description: {
    textAlign: 'center',
    marginTop: theme.spacing.xl * 2, // Increase the margin-top value
    '@media (max-width: 520px)': {
      textAlign: 'left',
      fontSize: theme.fontSizes.md,
    },
    paddingBottom: 20,
  },

  controls: {
    marginTop: theme.spacing.lg,
    display: 'flex',
    justifyContent: 'center',

    '@media (max-width: 520px)': {
      flexDirection: 'column',
    },
  },

  control: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing.md,
    },

    '@media (max-width: 520px)': {
      height: 42,
      fontSize: theme.fontSizes.md,

      '&:not(:first-of-type)': {
        marginTop: theme.spacing.md,
        marginLeft: 0,
      },
    },
  },

  link: {
    display: 'inline-block', // Maintain the inline nature of the buttons
    textDecoration: 'none', // Remove underline from the link
    marginLeft: theme.spacing.md,
  }
}));

export const HeroText: React.FC<HeroTextProps> = ({
  contactUsRef,
  scrollToRef,
}) => {
  const { classes } = useStyles();

  return (
    <Container className={classes.wrapper} size={1400}>
      <div className={classes.inner}>
        <Title className={classes.mainTitle}>
          Low-cost, efficient, and reliable{" "}
          <Text component="span" className={classes.highlight} inherit>
            Making Tax Digital
          </Text>{" "}
          software
        </Title>
        <Container p={0} size={600}>
          <Text size="lg" className={classes.subTitle}>
          Suitable for businesses, individuals, and accountants.
          </Text>
        </Container>
        <Container p={0} size={1000}>
          <Text size="lg" color="dimmed" className={classes.description}>
          Experience a hassle-free solution to Making Tax Digital without hidden charges or unnecessary complications. Choose TwoFive for a cost-effective and transparent approach to tax filing.
          </Text>
        </Container>


        <div className={classes.controls}>
          <Button
            className={classes.control}
            size="lg"
            variant="default"
            color="gray"
            onClick={() => scrollToRef(contactUsRef)}
          >
            Contact Us
          </Button>
          <a href="https://app.25tax.co.uk/auth/signup" className={classes.link}>
            <Button className={classes.control} size="lg">
              Signup
            </Button>
          </a>

        </div>
      </div>
    </Container>
  );
}

export default HeroText;

